import styled from "styled-components"

const Root = styled.button`
    background-color: #d34400;
    color: #fff;
    padding: 1rem;
    border-radius: 0.3rem;
    font-weight: 600;
    font-size: 1rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    border: none;
    width: 100%;
    justify-content: center;
    float: right;

    :hover {
        background-color: #ff5200;
        cursor: pointer;
    }

    :disabled {
        background-color: #cccccc;
        color: #666666;
    }
`;

export default Root;
