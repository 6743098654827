import styled from "styled-components"

const Root = styled.p`
    width: 100%;
    background-color: #ff8989;
    border-color: #ce0000;
    border-style: solid;
    border-width: 2px;
    margin: 1rem 0;
    padding: 0.5rem;
    box-sizing: border-box;
    color: #ad0000;
    font-weight: 400;
`;

export default Root;
