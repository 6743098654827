import styled from "styled-components"

const Root = styled.div`
    margin: 1rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;

    label {
        color: #18232e;
        font-size: 0.9rem;
        margin-left: 0.2rem;
        margin-bottom: 0.2rem;
        font-weight: 600;
        display: flex;
        align-items: center;
    }
`;

export default Root;