import styled from "styled-components"

const Root = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
`;

export default Root;
